import React from "react"
import styled from "styled-components"
//import MainLogoSvg from "../../../static/svg/logo_en.inline.svg"
import { Link } from "gatsby"
import "./MainLogo.scss"

const Logo = styled.div``

export const MainLogo = ({ lang = "lt" }) => {
  const to = lang === "lt" ? "/" : `/${lang}/`
  return (
    <Logo className={`Main-Logo ${lang}`}>
      <Link to={to} title="Our team">
        {/* <MainLogoSvg /> */}
      </Link>
      {/* <Link to="/">
       
      </Link> */}
    </Logo>
  )
}
