import React from "react"
//import { AnchorLink } from "gatsby-plugin-anchor-links"
import scrollTo from "gatsby-plugin-smoothscroll"

import "./Footer.scss"

// const variants = {
//   visible: { opacity: 1 },
//   hidden: { opacity: 0 },
// }

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer--Wrap">
        <div className="Copywrite">COPYRIGHT</div>
        <div className="Footer--Right">
          <div className="Solution">
            Solution by{" "}
            <a
              href="http://notperfect.com"
              rel="noopener noreferrer"
              className="link-underline--footer"
              target="_blank"
            >
              Not Perfect
            </a>
          </div>
        </div>
      </div>
      {/* <AnchorLink className="Top" to="/#page-top" /> */}
      <button onClick={() => scrollTo("#page-top")} className="Top"></button>
    </div>
  )
}

export default Footer
