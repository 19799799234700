import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

export default ({
  uid = "",
  className = "",
  lang = "lt",
  children,
  onClick = () => {},
}) => {
  const data = useStaticQuery(graphql`
    query {
      links: allLinksJson {
        nodes {
          uid
          slug
          title
          lang
        }
      }
    }
  `)

  let to = "/"
  const linkTo = data.links.nodes.find(
    item => item.uid === uid && item.lang === lang
  )
  if (linkTo) to = linkTo.slug

  return (
    <Link onClick={onClick} to={to} className={className}>
      {children ? children : <>{linkTo.title}</>}
    </Link>
  )
}
