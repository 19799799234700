const content = {
  menu: {
    menu1: [
      { title: "NEWS AND MEDIA", href: "/en/news/" },
      { title: "INDUSTRIES", href: "/" },
      { title: "SMARTPARK", href: "/" },
      { title: "SOCIAL<br/>RESPONSIBILITY", href: "/" },
      { title: "CONTACTS", href: "/en/contacts/" },
    ],
    menu2: {
      lt: [
        {
          title: "PRIEŽASTYS<br/>INVESTUOTI",
          href: "/lt/priezastys-investuoti/",
        },
        {
          title: "INVESTUOTOJAI",
          href: "/lt/investuotojai/",
        },
        { title: "VIETA", href: "/lt/vieta/" },
        { title: "SMARTPARK", href: "/lt/smartpark/" },

        // { title: "REASONS<br/>TO INVEST", href: "/#page-top" },
        // { title: "INVESTORS<br/>AND PARTNERS", href: "/#investors-and-partners" },
        // { title: "LOCATION", href: "/#location" },
      ],
      en: [
        { title: "REASONS TO<br/>INVEST", href: "/en/reasons-to-invest/" },
        {
          title: "INVESTORS",
          href: "/en/investors/",
        },
        { title: "LOCATION", href: "/en/location/" },
        { title: "SMARTPARK", href: "/en/smartpark/" },
      ],
      de: [
        {
          title: "GRÜNDE FÜR<br/>INVESTITIONEN",
          href: "/de/grunde-fur-investitionen/",
        },
        {
          title: "INVESTOREN",
          href: "/de/investoren/",
        },
        { title: "STANDORT", href: "/de/standort/" },
        { title: "SMARTPARK", href: "/de/smartpark/" },
      ],
    },
  },
}
export default content
