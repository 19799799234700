import React, { useState, useEffect } from "react"
import "./Menu.scss"
import classnames from "classnames"
//import { Link } from "../LinkWithPrevUrl/LinkWithPrevUrl"
import { Link } from "gatsby"

import anime from "animejs"
import { Transition } from "react-transition-group"

import content from "../../data/content"
import UidLink from "../UidLink/UidLink"
//import { AnchorLink } from "gatsby-plugin-anchor-links"

const animateHeaderIn = (node, show) => {
  const config = {}
  const configInner = {}

  const direction = node.dataset.direction
  // Using 101% instead of 100% to avoid rendering problems.
  // In order to create the "reveal" effect, the item slides moves in one direction and its inner element in the opposite direction.
  if (direction === "bt") {
    config.y = "101%"
    configInner.y = "-101%"
    configInner.x = "0%"
  } else if (direction === "tb") {
    config.y = "-101%"
    configInner.y = "101%"
    configInner.x = "0%"
  } else if (direction === "lr") {
    config.x = "-101%"
    configInner.x = "101%"
  } else if (direction === "rl") {
    config.x = "101%"
    configInner.x = "-101%"
  } else {
    config.x = "101%"
    config.y = "101%"
    configInner.x = "-101%"
    configInner.y = "-101%"
  }

  // config.y = "101%"
  // configInner.y = "-101%"
  // configInner.x = "0%"
  if (show) {
    anime({
      targets: node,
      translateX: "0%",
      translateY: "0%",
      duration: 900,
      easing: "easeOutQuint",
    })
    anime({
      targets: node.querySelector(".menu__item-inner"),
      translateX: "0%",
      translateY: "0%",
      easing: "easeOutQuint",
      duration: 900,
    })
  } else {
    anime({
      targets: node,
      translateX: config.x || 0,
      translateY: config.y || 0,
      easing: "easeInOutQuart",
      duration: 600,
      delay: 200,
    })
    anime({
      targets: node.querySelector(".menu__item-inner"),
      translateX: configInner.x || 0,
      translateY: configInner.y || 0,
      easing: "easeInOutQuart",
      duration: 600,
      delay: 200,
    })
  }

  if (node.querySelector(".mainmenu")) {
    let menuLinks = node.querySelectorAll(".mainmenu a")

    anime({
      targets: menuLinks,
      translateY: show ? ["50%", "0%"] : "100%",
      opacity: show ? [0, 1] : 0,
      easing: show ? "easeOutQuint" : "easeInOutQuart",
      delay: anime.stagger(
        show
          ? Math.round(500 / menuLinks.length)
          : Math.round(100 / menuLinks.length),
        { start: show ? 200 : 0, direction: show ? "normal" : "reverse" }
      ),
    })
  }
}

const MenuItem = props => {
  const ref = React.useRef()
  useEffect(() => {
    animateHeaderIn(ref.current, false)
  }, [])
  return (
    <Transition
      in={props.animate}
      onEnter={node => animateHeaderIn(node, true)}
      onExit={node => animateHeaderIn(node, false)}
      timeout={1000}
    >
      <div
        ref={ref}
        data-direction={props.direction}
        className={`menu__item menu__item--${props.item}`}
      >
        <div className="menu__item-inner">{props.children}</div>
      </div>
    </Transition>
  )
}

export const Menu = props => {
  const [isOpen, setIsOpen] = useState(false)
  const homePageURL = props.lang === "lt" ? "/" : `/${props.lang}/`
  return (
    <>
      {/* <button
        style={{ zIndex: 300, position: "absolute" }}
        onClick={() => {
          props.setIsMenuOpen(!props.isMenuOpen)
        }}
      >
        OPEN
      </button> */}

      <Transition
        in={props.isMenuOpen}
        timeout={250}
        onEnter={node => {
          setIsOpen(true)
        }}
        onExit={node => {
          setTimeout(() => {
            setIsOpen(false)
          }, 1000)
        }}
      >
        <nav className={classnames("menu", { isOpen: isOpen })}>
          <MenuItem direction="bt" animate={props.isMenuOpen} item={1}>
            <div className="mainmenu">
              {content.menu?.menu2[props.lang]?.map((item, key) => (
                <Link
                  to={item.href}
                  key={`menu_2${key}`}
                  onClick={() => {
                    props.setIsMenuOpen(false)
                  }}
                  state={{ scrollTo: item.href.split("#")[1] }}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                </Link>
                // <AnchorLink
                //   to={item.href}
                //   key={`menu_2${key}`}
                //   title={item.title}
                //   stripHash
                // >
                //   <span
                //     onClick={() => {
                //       props.setIsMenuOpen(false)
                //     }}
                //     dangerouslySetInnerHTML={{ __html: item.title }}
                //   ></span>
                // </AnchorLink>
              ))}
            </div>
          </MenuItem>

          <MenuItem direction="tb" animate={props.isMenuOpen} item={2}>
            <div className="menu__item-map">
              <Link
                to={props.mapLink.slug}
                onClick={() => {
                  props.setIsMenuOpen(false)
                }}
              >
                <span>{props.mapLink.title}</span>
              </Link>
            </div>
          </MenuItem>

          <MenuItem direction="rl" animate={props.isMenuOpen} item={3}>
            <div className="mainmenu">
              {props.menu3.map((item, key) => (
                <Link
                  onClick={() => {
                    props.setIsMenuOpen(false)
                  }}
                  to={item.slug}
                  key={`menu_1${key}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                </Link>
              ))}
            </div>
          </MenuItem>

          <MenuItem direction="rl" animate={props.isMenuOpen} item={4}>
            <div className="menu__item-inner"></div>
          </MenuItem>

          <MenuItem direction="tb" animate={props.isMenuOpen} item={5}>
            <div className="menu__item-inner">
              <UidLink
                onClick={() => {
                  props.setIsMenuOpen(false)
                }}
                lang={props.lang}
                uid={`stories`}
              />
            </div>
          </MenuItem>

          <MenuItem direction="bt" animate={props.isMenuOpen} item={6}>
            <div className="menu__item-video">
              <Link
                onClick={() => {
                  props.setIsMenuOpen(false)
                  const play = document.querySelector(".VideoButton")
                  if (play) {
                    setTimeout(() => {
                      play.click()
                    }, 1000)
                  }
                }}
                state={{ playVideo: true }}
                to={homePageURL}
              >
                <span className="VideoButton--Circle"></span>
              </Link>
            </div>
          </MenuItem>
        </nav>
      </Transition>
    </>
  )
}
