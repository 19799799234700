import React from "react"
import debounce from "lodash.debounce"
import Nav from "../Nav/Nav"
import scrollTo from "gatsby-plugin-smoothscroll"
import { graphql } from "gatsby"

import InterfaceContext, {
  defaultInterfaceContext,
} from "../../context/InterfaceContext"

import "../../styles/layout.scss"

import Footer from "../Footer/Footer"
import { ParallaxProvider } from "react-scroll-parallax"
import { ContextProviderComponent } from "../../context/LayoutContext"
//import { Consumer } from "../../context/LayoutContext"
import layout from "../../util/layout"

class Layout extends React.Component {
  //static whyDidYouRender = true

  constructor(props) {
    super(props)

    //this.handleHashChange = this.handleHashChange.bind(this)
    this.setIsMenuOpen = this.setIsMenuOpen.bind(this)
    this.setIsLocaleOpen = this.setIsLocaleOpen.bind(this)
    this.state = {
      interface: {
        ...defaultInterfaceContext,
        lang: this.props.pageContext.lang,
        isMenuOpen: false,
        setIsMenuOpen: isOpen => {
          this.setState(state => ({
            interface: {
              ...state.interface,
              isMenuOpen: isOpen,
            },
          }))
        },
        setLang: lang => {
          this.setState(state => ({
            interface: {
              ...state.interface,
              lang: lang,
            },
          }))
        },
        showHeroVideo: () => {
          scrollTo("#page-top")
          this.setState(state => ({
            interface: {
              ...state.interface,
              heroStatus: "playing",
              navMenuStatus: "hide",
            },
          }))
        },
        closeHeroVideo: () => {
          this.setState(state => ({
            interface: {
              ...state.interface,
              heroStatus: "initial",
              navMenuStatus: "initial",
            },
          }))
        },
      },
      navConfig: this.props.pageContext.navConfig,
      windowWidth: 1280,
      windowHeight: 800,
      isMenuOpen: false,
      isLocaleOpen: false,
      layout: {
        small: false,
        medium: true,
        large: false,
      },
      isReady: false,
    }
  }

  componentDidMount() {
    this.handleResize()
    //this.handleHashChange()
    if (typeof window !== `undefined`)
      window.addEventListener("resize", this.handleResize)
    //window.addEventListener("hashchange", this.handleHashChange)
  }

  UNSAFE_componentWillUnmount() {
    if (typeof window !== `undefined`)
      window.removeEventListener("resize", this.handleResize)
    //window.removeEventListener("hashchange", this.handleHashChange)
  }

  setIsMenuOpen(isOpen) {
    this.setState({ isMenuOpen: isOpen })
  }

  setIsLocaleOpen(isOpen) {
    this.setState({ isLocaleOpen: isOpen })
  }

  handleHashChange(e) {
    //console.log("change")
  }

  handleResize = debounce(() => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      layout: layout.all,
      isReady: true,
    })
  }, 10)

  render() {
    // if (!this.state.isReady) return <></>;
    return (
      <div id="page-top" className="page--container">
        <div className="page">
          <InterfaceContext.Provider value={this.state.interface}>
            <InterfaceContext.Consumer>
              {({ navMenuStatus, lang }) => (
                <ContextProviderComponent>
                  {/* <div>Layout : {this.props.pageContext.lang}</div>
                  <div>Context : {lang}</div> */}

                  <Nav
                    status={navMenuStatus}
                    theme={this.props.pageContext?.navConfig?.theme}
                    lang={this.props.pageContext?.lang}
                    isLocaleOpen={this.state.isLocaleOpen}
                    setIsLocaleOpen={this.setIsLocaleOpen}
                    isMenuOpen={this.state.interface.isMenuOpen}
                    setIsMenuOpen={this.state.interface.setIsMenuOpen}
                    config={this.state.navConfig}
                    setLang={this.state.interface.setlang}
                  ></Nav>

                  <ParallaxProvider>
                    {/* <Page isReady={this.state.isReady} {...this.state} /> */}
                    {this.props.children}
                    <Footer></Footer>
                  </ParallaxProvider>
                </ContextProviderComponent>
              )}
            </InterfaceContext.Consumer>
          </InterfaceContext.Provider>
        </div>
      </div>
    )
  }
}

export default Layout
