import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import InterfaceContext from "../../context/InterfaceContext"

import "./LocaleSelect.scss"

export const LocaleSelect = props => {
  const langs = [
    { title: "lt", slug: "/" },
    { title: "en", slug: "/en/" },
    { title: "de", slug: "/de/" },
  ]
  const fLangs = langs.filter(lang => lang.title !== props.lang)
  return (
    <InterfaceContext.Consumer>
      {({ setLang }) => (
        <div className={classnames(`LocaleSelect`, { isOpen: props.isOpen })}>
          <div className="LocaleSelect--Wrap">
            <div className="LocaleSelect--Display">
              <button
                onClick={() => {
                  props.setIsOpen(!props.isOpen)
                }}
              >
                {props.lang}
              </button>
            </div>
            <div className="LocaleSelect--Options">
              {fLangs.map(lang => (
                <Link
                  key={`lang_${lang.title}`}
                  onClick={() => {
                    setLang(lang.title)
                    props.setIsOpen(false)
                  }}
                  to={lang.slug}
                >
                  {lang.title.toUpperCase()}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </InterfaceContext.Consumer>
  )
}
