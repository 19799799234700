import React from "react"

export const defaultInterfaceContext = {
  lang: "en",
  setLang: lang => {
    console.log(lang)
  },
  isMenuOpen: false,
  setIsMenuOpen: isOpen => {},
  isDesktopViewport: null,
  cartStatus: "initial",
  toggleCart: () => {},
  productImageFeatured: null,
  featureProductImage: () => {},
  currentProductImages: [],
  setCurrentProductImages: () => {},
  productImagesBrowserStatus: "initial",
  toggleProductImagesBrowser: () => {},
  contributorAreaStatus: "initial",
  toggleContributorArea: () => {},
  navMenuStatus: "initial",
  navMenuHide: () => {},
  navMenuShow: () => {},
  heroStatus: "initial",
  toggleHero: () => {},
  closeHeroVideo: () => {},
  showHeroVideo: () => {},
}

export default React.createContext(defaultInterfaceContext)
