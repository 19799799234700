// Context.js
import React from "react"

const defaultContextValue = {
  data: {
    // set your initial data shape here
    menuOpen: false,
    videoPlay: false,
    lang: "de",
    navConfig: {
      theme: "title",
    },
  },
  set: () => {},
}

const LayoutContext = React.createContext(defaultContextValue)
const Provider = LayoutContext.Provider
const Consumer = LayoutContext.Consumer

class ContextProviderComponent extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { LayoutContext as default, Consumer, ContextProviderComponent }
