exports.components = {
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-dynamic-js": () => import("./../../../src/templates/dynamic.js" /* webpackChunkName: "component---src-templates-dynamic-js" */),
  "component---src-templates-map-js": () => import("./../../../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/newsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-stories-item-js": () => import("./../../../src/templates/storiesItem.js" /* webpackChunkName: "component---src-templates-stories-item-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */)
}

