import React from "react"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}

export const onClientEntry = () => {
  // if (process.env.NODE_ENV !== "production") {
  //   const whyDidYouRender = require("@welldone-software/why-did-you-render")
  //   whyDidYouRender(React, {
  //     trackAllPureComponents: true,
  //   })
  // }
}

export const shouldUpdateScroll = ({
  //prevRouterProps: { location: prevLocation },
  routerProps: { location },
}) => {
  //const isModal = _.get(location, "state.modal")

  const preventUpdateScroll =
    location && location.state && location.state.noScroll

  return !preventUpdateScroll
}

// export const wrapPageElement = ({ element, props }) => {
//   console.log(props)
//   return (
//     <div className="wrapPageElement">
//       <div>gatsby-browser: {props.pageContext.lang}</div>
//       {element}
//     </div>
//   )
// }
