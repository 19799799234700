import React from "react"
import Translation from "../Translation/Translation"
import classnames from "classnames"

import "./HamburgerButton.scss"

export const HamburgerButton = props => {
  return (
    <button
      onClick={() => {
        props.setIsMenuOpen(!props.isMenuOpen)
      }}
      //onClick={() => setisOpen(!isOpen)}
      className={classnames(`HamburgerButton`, { isOpen: props.isMenuOpen })}
    >
      <div className="squares-wrap">
        <div className="squares">
          <div className="square square-0"></div>
          <div className="square square-1"></div>
          <div className="square square-2"></div>
          <div className="square square-3"></div>
          <div className="square square-4"></div>
          <div className="square square-5"></div>
          <div className="square square-6"></div>
          <div className="square square-7"></div>
          <div className="square square-8"></div>
        </div>
      </div>
      <div className="label-wrap">
        <div className="label label-open">
          <Translation slug="hamburger_menu" />
        </div>
        <div className="label label-close">
          <Translation slug="hamburger_close" />
        </div>
      </div>
    </button>
  )
}
