import React, { useContext } from "react"

import { graphql, useStaticQuery } from "gatsby"
import InterfaceContext from "../../context/InterfaceContext"

export default ({ lang, slug = "hero_play_button_label" }) => {
  const context = useContext(InterfaceContext)

  const data = useStaticQuery(graphql`
    query Translations {
      translates: allTranslationsJson {
        nodes {
          lang
          key
          value
        }
      }
    }
  `)
  const translatedRow = data.translates.nodes.find(
    item => item.lang === context.lang && item.key === slug
  )
  let translated = slug
  if (translatedRow) translated = translatedRow.value

  return (
    <InterfaceContext.Consumer>
      {({ lang }) => <>{translated}</>}
    </InterfaceContext.Consumer>
  )
}
