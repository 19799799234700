import React from "react"
import SubmenuItem from "../Submenu/SubmenuItem"

import "./Submenu.scss"

export default ({ children, links }) => {
  return (
    <div className="Submenu">
      {children}
      <ul className="Submenu-List">
        {links.map((link, index) => {
          return (
            <li className={`li-` + link.uid} key={`menu_${index}`}>
              <SubmenuItem item={link} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
