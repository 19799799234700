import React from "react"
import { Link } from "gatsby"

export default ({ item: { title, slug, uid } }) => {
  return (
    <Link
      className={uid}
      activeClassName="active"
      partiallyActive={true}
      to={slug}
    >
      <span dangerouslySetInnerHTML={{ __html: title }}></span>
    </Link>
  )
}
