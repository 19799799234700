import React, { useState } from "react"
import classNames from "classnames"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Menu } from "../Menu/Menu"
import { LocaleSelect } from "../LocaleSelect/LocaleSelect"
import { HamburgerButton } from "../HamburgerButton/HamburgerButton"
import { MainLogo } from "../MainLogo/MainLogo"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Submenu from "../Nav/Submenu/Submenu"

import "./Nav.scss"

const NavigationWrap = styled.div`
  width: 100%;
`

const Navigation = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 100;
  transition-duration: 0.1s;
  .LocaleSelect,
  .Submenu,
  .Main-Logo {
    transition-duration: 0.1s;
  }

  &.hide {
    transform: translateY(-100%);
  }
`

const StyledHeader = styled.header``
export default ({
  config,
  children,
  isMenuOpen,
  setIsMenuOpen,
  isLocaleOpen,
  setIsLocaleOpen,
  lang,

  theme,
  status,
}) => {
  const [navClass, setNavClass] = useState("show")

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const shoudBeClass = {
        top: currPos.y > -5,
        small: currPos.y < -5,
        //hide: currPos.y < prevPos.y && currPos.y < -300,
      }
      setNavClass(classNames(shoudBeClass))
    },
    [navClass]
  )

  const data = useStaticQuery(graphql`
    query {
      topDynamicLinks: allLinksJson(filter: { groups: { eq: "topDynamic" } }) {
        edges {
          node {
            id
            slug
            title
            lang
            uid
          }
        }
      }

      topInnerLiks: allLinksJson(filter: { groups: { eq: "topInner" } }) {
        edges {
          node {
            id
            slug
            title
            lang
            uid
          }
        }
      }

      map: allLinksJson(filter: { uid: { eq: "map" } }) {
        edges {
          node {
            id
            slug
            title
            lang
          }
        }
      }
    }
  `)

  const mapLink = data.map.edges?.find(({ node }) => node.lang === lang)?.node

  const topInnerLiks = data.topInnerLiks.edges
    .filter(({ node }) => node.lang === lang)
    .map(item => item.node)

  const topDynamicLinks = data.topDynamicLinks.edges
    .filter(({ node }) => node.lang === lang)
    .map(item => item.node)

  return (
    <StyledHeader className={`Main-Header ${navClass} ${status}`}>
      <Navigation className={`Main-Navigation`}>
        <NavigationWrap>
          <MainLogo lang={lang} />
          {theme === "title" ? (
            <>
              <Submenu links={topDynamicLinks}>
                <LocaleSelect
                  lang={lang}
                  isOpen={isLocaleOpen}
                  setIsOpen={setIsLocaleOpen}
                />
              </Submenu>
            </>
          ) : (
            <Submenu links={topInnerLiks} />
          )}

          {children}
        </NavigationWrap>
      </Navigation>
      <HamburgerButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Menu
        mapLink={mapLink}
        menu3={topInnerLiks}
        lang={lang}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    </StyledHeader>
  )
}
